import { useEffect } from "react";
import Router from "next/router";
import { useUser } from "lib/auth/hook";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "theme";

const Login = (): JSX.Element => {
  const { loggedIn } = useUser();
  const { loginWithRedirect } = useAuth0();
  const { key } = useTheme();

  useEffect(() => {
    if (loggedIn) {
      Router.push("/");
    } else {
      loginWithRedirect({
        redirectUri: `${window.location.origin}/callback`,
        ttTheme: key,
      });
    }
  }, [loggedIn, loginWithRedirect, key]);
  return null;
};

export default Login;
